import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Σύγχρονος χορός";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>Ο Σύγχρονος χορός εξελίσσεται διαρκώς, εκφράζοντας την εποχή του.</P>
        <P>
          Σκοπός του μαθήματος είναι να δουλέψουμε το σώμα μας ως βασικό
          εργαλείο του χορευτή. Μέσα από μια σειρά ασκήσεων, αυτοσχεδιασμών και
          χορογραφιών θα δουλέψουμε την ταχύτητα, τη μεταφορά βάρους, την
          εναλλαγή δυναμικής/ποιότητας και τη ροή της κίνησης, έχοντας ως κέντρο
          τη σπονδυλική μας στήλη.
        </P>
        <P>
          Θα κατανοήσουμε και θα δώσουμε έμφαση στη χρήση των χεριών και του
          κεφαλιού για την απόκτηση ενός μαλακού κορμού, δίνοντας έτσι
          δυνατότητα στο σώμα να μπορεί να ανταποκριθεί σε οποιοδήποτε κινητικό
          λεξιλόγιο.
        </P>
        <P>
          Διδάσκονται τα συστήματα Graham, Hawkins, Limon, Cunningham, Release,
          Horton και άλλα.
        </P>
      </Section.Main>
    </Layout>
  );
}
